import React, { useState, useEffect } from "react"
import { Helmet } from "react-helmet"
import { graphql } from "gatsby"
import 'react-h5-audio-player/lib/styles.css';

import piano from "./article-04/piano.mp3"
import fiddle from "./article-04/fiddle.m4a"
import electronic from "./article-04/electronic.m4a"
import ambient from "./article-04/ambient.wav"
import carwash from "./article-04/carwash.m4a"

import AudioPlayer, { RHAP_UI } from 'react-h5-audio-player'


import PreFooter from "../../../components/athena/preFooter"
import ScrollOut from "scroll-out"
import Contributors from "../../../components/athena/contributors"
import MoreArticles from "../../../components/athena/moreArticlesJoy"

import Layout, { athenaTheme } from "../../../components/layout"

import ScrollProgressBar from "../../../components/scrollProgressBar"
import bkgblur from "../../../images/athena-04-resilience/04-sounds/bkg-blur.png"
import nature from "../../../images/athena-04-resilience/04-sounds/nature.png"
import collective from "../../../images/athena-04-resilience/04-sounds/collective.png"
import therapy from "../../../images/athena-04-resilience/04-sounds/therapy.png"

import {TwitterIcon, TwitterShareButton, LinkedinIcon, LinkedinShareButton, FacebookShareButton, FacebookIcon} from 'react-share';

import gordon from "../../../images/athena-04-resilience/04-sounds/gordon.png"
import anna from "../../../images/athena-04-resilience/04-sounds/anna.png"
import dustin from "../../../images/athena-04-resilience/04-sounds/dustin.png"
import christian from "../../../images/athena-04-resilience/04-sounds/christian.png"
import sarah from "../../../images/athena-04-resilience/04-sounds/sarah.png"

import styles from "./sounds.module.scss"

let theme = {
  ...athenaTheme,
  background: styles.background,
  foreground: styles.foreground,
}

const Article = ({ data }) => {
  const article = data.craft.article
  const seo = article.seomatic
  //const coverImages = article.coverImages ? article.coverImages[0] : null

  let [heroFade, setHeroFade] = useState(true)
  useEffect(() => {
    setHeroFade(0)
    let timeoutId = setTimeout(() => {
      setHeroFade(1)
      timeoutId = setTimeout(() => {
        setHeroFade(2)
          timeoutId = setTimeout(() => {
          setHeroFade(3)
            timeoutId = setTimeout(() => {
            setHeroFade(4)
          }, 250)
        }, 500)
      }, 250)
    }, 250)
    return () => clearTimeout(timeoutId)
  }, [])

  let group = index => ({
    style: {
      transition: "opacity .6s ease-in-out",
      opacity: heroFade >= index ? 1 : 0.0125,
    },
  })


  let [natureExpand, setNatureExpand] = useState(false)
  const toggleNatureExpand = () => setNatureExpand(!natureExpand);

  let [musicExpand, setMusicExpand] = useState(false)
  const toggleMusicExpand = () => setMusicExpand(!musicExpand);

  let [therapyExpand, setTherapyExpand] = useState(false)
  const toggleTherapyExpand = () => setTherapyExpand(!therapyExpand);

  useEffect(() => {
    let so = ScrollOut({
      offset: 1070,
      cssProps: {
        viewportY: true,
      },
    })
    return so.teardown
  }, [])

  return (
    <Layout seo={seo} mode="athena" theme={theme} {...group(0)}>
      <Helmet>
        <body className={`athena ${styles.body}`}/>
      </Helmet>
      <article>
        <ScrollProgressBar />
        <div className={styles.article} {...group(2)}>
          <div className={styles.hero} data-scroll>
          <div className={styles.heroOverlay}></div>
            <div className={styles.heroVideo}>
              <video autoPlay muted playsInline>
                <source src="https://zj-v6.imgix.net/athena-content/I04-Exploring-Joy/I04-A04-Sounds-of-Resilience/hero-updated.mp4" type="video/mp4" />
              </video>
            </div>
            <div className={styles.issue} {...group(3)}>
              <p className="a-h-5">Issue 04 / Exploring Joy, Reimagining Resilience </p>
            </div>
            <div className={styles.title} {...group(4)}>
              <h1 className="a-h-display">{article.title}</h1>
            </div>
          </div>
          <div className={`${styles.articleBodyTop}`}>
            <div className="wrapper">
              <div className={styles.readingTime}>
                <p className={styles.reading}>7 min reading time</p>
                <p className={styles.readingMobile}>7 min read</p>
                <div className={styles.socialContainer}>
                  <p className={styles.share}>Share —</p>
                  <TwitterShareButton
                  url={'https://zeusjones.com/athena/articles/sounds-of-joyful-resilience/'}
                  title={article.title}
                  className={styles.shareButtons}
                  via = 'zeusjones'
                  >
                    <TwitterIcon />
                  </TwitterShareButton>
                
                  <LinkedinShareButton
                  url={'https://zeusjones.com/athena/articles/sounds-of-joyful-resilience/'}
                  title={article.title}
                  className={styles.shareButtons}
                  via = 'zeusjones'
                  >
                    <LinkedinIcon />
                  </LinkedinShareButton>
              
                  <FacebookShareButton
                  url={'https://zeusjones.com/athena/articles/sounds-of-joyful-resilience/'}
                  title={article.title}
                  className={styles.shareButtons}
                  via = 'zeusjones'
                  >
                    <FacebookIcon />
                  </FacebookShareButton>
                </div>
              </div>
              <div className="columns is-desktop">
                <div className="column is-12-tablet is-11-desktop">
                  <h2 className={`${styles.introHeading}`}>
                  S<em>o</em>und mak<span>e</span>s us feel <span>T</span>hings.
                  <br />
                  <br />
                  The hi<span>g</span>h-pitched scree<span>c</span>h <em>o</em>f tires skidding to <span>a</span> halt startl<span>e</span>s us. <span>T</span>he soft cooing <em>o</em>f a h<span>a</span>ppy i<span>n</span>fant melts <span>u</span>s. Th<span>e</span> dominati<span>n</span>g <em>r</em>umble <em>o</em>f a pow<span>e</span>rful wate<span>r</span>fall <em>o</em>verwhelms us.
                  </h2>
                </div>
              </div>
              <div className="columns is-desktop">
                <div className="column is-12-tablet is-5-desktop">
                </div>
                <div className="column is-12-tablet is-7-desktop">
                  <div className="user-content-athena">
                    <p>Sound is ephemeral and ever moving, making it a particularly interesting piece of our physical environment and emotional landscape. Most other sensory features of reality are persistent and tangible, with color, shape, size, and texture; allowing for our analysis by simply staying in one place.</p>
                    <p>Sound is different. It can flow, pierce, soothe, and warn but never endure for more than a few seconds. It is fleeting. And yet, sound moves us. And it may even build joyful resilience within us.</p>
                    <p>We asked a few Joneses to think about their personal connections to joyful resilience and translate that into sound. Here's some of what they shared with&nbsp;us.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={`${styles.articleBodyBottom}`}>
            <div className="wrapper">
              <div className={`columns is-tablet ${styles.articleBodyBottomRow}`}>
                <div className={`${styles.columnLeft} column is-5-tablet`}>
                  <h3 className={styles.h3}>
                    <span>Pia<b>n</b>o</span>
                    <span>impr<em>o</em>v</span>
                    <span role="img" aria-label="Piano Emoji">🎹</span>
                  </h3>
                  <AudioPlayer
                    className={styles.newPlayer}
                    src={piano}
                    layout="horizontal-reverse"
                    controls
                    showFilledProgress={false}
                    customProgressBarSection={[
                    RHAP_UI.CURRENT_TIME,
                    // RHAP_UI.DURATION
                    <p>&nbsp;/ 01:55</p>
                    ]}
                    defaultCurrentTime={'00:00'}
                    showSkipControls={false}
                    showJumpControls={false}
                    customVolumeControls={[]}
                    customAdditionalControls={[]}
                    />
                </div>
                <div className={`${styles.columnRight} column is-7-tablet`}>
                  <div className={styles.authorDetails}>
                    <img src={gordon} alt="Gordon McIntyre-Lee" />
                    <div className={styles.name}>
                      <h4>Gordon McIntyre-Lee</h4>
                      <p className="a-h-5">Designer</p>
                    </div>
                  </div>
                  <p>“I started plinking on the piano a few months before the start of the pandemic, and found that it was oddly therapeutic, to the point that I would often lose track of time and spend several hours messing around seeing what sounded good. I'm still not very technically good, but I enjoy playing and have found that it's helped keep me sane as the rest of the world has changed.”</p>
                </div>
              </div>
              <div className={`columns is-tablet ${styles.articleBodyBottomRow}`}>
                <div className={`${styles.columnLeft} column is-5-tablet`}>
                  <h3 className={styles.h3}>
                    <span><b>T</b>raditional</span>
                    <span><b>F</b>olk</span> 
                    <span>S<em>o</em>ng</span>
                    <span>on</span> 
                    <span>Fiddl<b>e</b></span>
                    <span role="img" aria-label="Fiddle Emoji">🎻</span></h3>
                    <AudioPlayer
                    className={styles.newPlayer}
                    src={fiddle}
                    layout="horizontal-reverse"
                    controls
                    showFilledProgress={false}
                    customProgressBarSection={[
                    RHAP_UI.CURRENT_TIME,
                    <p>&nbsp;/ 01:27</p>
                    ]}
                    defaultCurrentTime={'00:00'}
                    showSkipControls={false}
                    showJumpControls={false}
                    customVolumeControls={[]}
                    customAdditionalControls={[]}
                    />
                </div>
                <div className={`${styles.columnRight} column is-7-tablet`}>
                  <div className={styles.authorDetails}>
                    <img src={anna} alt="Gordon McIntyre-Lee" />
                    <div className={styles.name}>
                      <h4>Anna Evenson</h4>
                      <p className="a-h-5">Producer</p>
                    </div>
                  </div>
                  <p>“This isn't my original composition but it is my version of a dance tune I learned from my teacher. Norwegian dance/fiddle tunes like this are passed down and taught by ear only, there isn't any sheet music to learn from. So while the core of the tune remains, it can evolve over time as it's passed from teacher to student, each person adding their own style. I started learning hardanger fiddle (pronounced har-dung-er, also called hardingfele in Norwegian) this last summer although I've played the violin for years. The hardingfele is a stringed instrument from Norway that is traditionally used to accompany dancers. There are four top strings like a violin and then 4-5 sympathetic strings underneath the main strings that resonate when the top 4 are played. It's also tuned differently than a violin. To me joyful resilience is more than just the sound I hear—on the surface this is an upbeat folk dance tune. When I'm playing, joyful resilience comes through in the connection I feel to my instrument and my heritage, the healing and relaxation music brings me and enjoyment I feel when I have a chance to share with others.”</p>
                </div>
              </div>
            </div>

            <div className={styles.windowContainer}>
              <div className={styles.window} style={{backgroundImage: `url(${bkgblur})`}}>
                <p>Consider <span role="img" aria-label="Sparkle Emoji">✨</span></p>
                <h3 className={styles.h3}>
                  <span><b>T</b>he</span>
                  <span>Soni<b>c</b></span>
                  <span><b>P</b>ower</span>
                  <span><em>o</em>f</span>
                  <img src={nature} alt="blob" />
                  <span>Nat<b>u</b>re</span>
                </h3>
                <p>The sounds of nature have a calming power. <a href="https://greatergood.berkeley.edu/article/item/why_forest_bathing_is_good_for_your_health" target="_blank" rel="noreferrer">Forest bathing</a> and other methods of spending time surrounded by the natural environment help destress and reset our mental state while eliciting joy and wonder at the world around us. Rustling leaves, a gentle-flowing creek, and melodious bird calls bring our minds back to a primordial place, before modern expectations began to drive our decisions and dominate our time.</p>
                {natureExpand &&
                  <p>Nature sounds are so powerfully ingrained within us that according to a <a href="https://www.nature.com/articles/srep45273" target="_blank" rel="noreferrer">recent study</a>, even listening to recordings of natural sounds stimulates our parasympathetic nervous system responsible for relaxation, calming us down and making us feel in control even when we're stressed.</p>
                }
                <button onClick={() => toggleNatureExpand()}>
                  <>
                  {natureExpand ? 'Collapse -' : 'Expand +'}
                  </>
                </button>
                
              </div>
            </div>
            
            <div className="wrapper">
              <div className={`columns is-tablet ${styles.articleBodyBottomRow}`}>
                <div className={`${styles.columnLeft} column is-5-tablet`}>
                  <h3 className={styles.h3}>
                  <span><b>E</b>lec<b>t</b>roni<b>c</b></span>
                  <span>C<em>o</em>mposi<b>t</b>ion</span>
                  <span role="img" aria-label="Bird Emoji">🐦</span></h3>

                  <AudioPlayer
                    className={styles.newPlayer}
                    src={electronic}
                    layout="horizontal-reverse"
                    controls
                    showFilledProgress={false}
                    customProgressBarSection={[
                    RHAP_UI.CURRENT_TIME,
                    <p>&nbsp;/ 03:02</p>
                    ]}
                    defaultCurrentTime={'00:00'}
                    showSkipControls={false}
                    showJumpControls={false}
                    customVolumeControls={[]}
                    customAdditionalControls={[]}
                    />
                </div>
                <div className={`${styles.columnRight} column is-7-tablet`}>
                  <div className={styles.authorDetails}>
                    <img src={dustin} alt="Dustin Studelska" />
                    <div className={styles.name}>
                      <h4>Dustin Studelska</h4>
                      <p className="a-h-5">Strategist</p>
                    </div>
                  </div>
                  <p>“I've played guitar for a long time, but have slowly begun the journey towards electronic music production relatively recently. It is a whole new world that has so many nooks and crannies to explore that I'm always hearing new possibilities I didn't even know existed. That exploratory element brings me a lot of joy. But to me resilience has a bit of an edge to it. Even if it is joyful, there's still an aspect of learning that is non-trivial. So my joyful resilience is less about being cheery and playful and more about breaking through the clouds to experiencing things that change your perspective. Joy gives this journey a sort of ascending feeling. It doesn't erase our struggles, but it builds our confidence and conviction that we can live life on our terms even if we don't always get it right.”</p>
                </div>
              </div>
              
              <div className={`columns is-tablet ${styles.articleBodyBottomRow}`}>
                <div className={`${styles.columnLeft} column is-5-tablet`}>
                  <h3 className={styles.h3}>
                  <span><b>A</b>I-Assist<b>e</b>d</span>
                  <span>Ambie<b>n</b>t</span>
                  <span>Tra<b>c</b>k</span>
                  <span role="img" aria-label="Night Sky">🌌</span></h3>
                  <AudioPlayer
                    className={styles.newPlayer}
                    src={ambient}
                    layout="horizontal-reverse"
                    controls
                    showFilledProgress={false}
                    customProgressBarSection={[
                    RHAP_UI.CURRENT_TIME,
                    <p>&nbsp;/ 08:25</p>
                    ]}
                    defaultCurrentTime={'00:00'}
                    showSkipControls={false}
                    showJumpControls={false}
                    customVolumeControls={[]}
                    customAdditionalControls={[]}
                    />
                </div>
                <div className={`${styles.columnRight} column is-7-tablet`}>
                  <div className={styles.authorDetails}>
                    <img src={christian} alt="Christian Erickson" />
                    <div className={styles.name}>
                      <h4>Christian Erickson</h4>
                      <p className="a-h-5">CEO, Creative, Partner</p>
                    </div>
                  </div>
                  <p>“During the early days of the pandemic, I got very into generative music—which is typically music generated by machines from a set of instructions. A lot of people at Zeus know I have a deep love for ambient music, and usually listen to spacey, minimal things while working which helps me stay calm and focused. So each morning in mid-2020 I would set up a new generative music setup—which involved feeding riffs, chords and melodies into various randomizing or algorithmic tools, setting up sounds and instruments to play what comes out, and setting up a mix to make it sound the way I wanted. And once I had done that I would press play and let the computer entertain me for hours with dreamy (sometimes dreary) random music. When I heard stuff I thought was particularly cool I would press record, and I've since <a href="https://open.spotify.com/artist/1YprXGHEajOinrwaPBF2o8?si=FBkBOU9gRq-z8eeZrA2p9A" target="_blank" rel="noreferrer">released some of those recordings on Spotify.</a> But others I just kept for myself. I think of it as almost like a highly custom windchime—something that you don't quite have control over, but if you've set it up right it will sound pleasing in most conditions. And they were a good creative outlet for me and also created a great atmosphere in my home office as I was getting used to the idea of being remote.”</p>
                </div>
              </div>
            </div>
            
            <div className={styles.windowContainer}>
              <div className={styles.window} style={{backgroundImage: `url(${bkgblur})`}}>
                <p>Consider <span role="img" aria-label="Sparkle">✨</span></p>
                <h3 className={styles.h3}>
                <span>M<b>u</b>sic</span>
                <span>a<b>n</b>d</span>
                <img src={collective} alt="blob" />
                <span>C<em>o</em>llective</span>
                <span><b>C</b>reativity</span>
                </h3>
                <p>Simply interacting with music can bring cognitive benefits. According to <a target="_blank" rel="noopener noreferrer" href="https://www.hopkinsmedicine.org/health/wellness-and-prevention/keep-your-brain-young-with-music">Johns Hopkins</a>, listening to music regularly can help our brains become more resilient as we age, reduce blood pressure, and boost creativity. Playing music amplifies these benefits even further and improves neural flexibility. <a href="https://blogs.scientificamerican.com/guest-blog/how-sound-can-be-an-ally-or-an-enemy-of-a-healthy-brain/" target="_blank" rel="noreferrer">Neurobiologists</a> can even tell which research subjects are musicians based on how smooth and clean their brainwaves are during&nbsp;a&nbsp;scan.</p>
                {musicExpand &&
                <>
                  <p>Music helps us process trauma too. <a href="https://www.apa.org/pubs/journals/features/trm-h0099396.pdf" target="_blank" rel="noreferrer">Research</a> shows that war-affected children who participated in exploratory music-making programs in their community were found to have improved cognitive stimulation, attention and focus, engendering greater psychological resilience to their trauma while having fun in the process. A similar study shows that “musical sense-making” helps children build self-awareness, a sense of personal agency, and feelings of belonging.</p>
                  <p>Even amid oppression music making can reinforce a sense of humanity. Filmmaker and author Marilena Umuhoza Delli and music producer Ian Brennan scout and record music specifically with this in mind. The duo has traveled the globe recording the disenfranchised and dehumanized, from Viet Cong veterans in Vietnam to inmates in Malawi. They've even recorded music from ostracized women in Ghana accused of witchcraft. The women sung songs in their native languages and chanted lyrics together and individually, conveying their experiences with oppression and their resilience to keep living and healing.</p>
                  </>
                }
                <button onClick={() => toggleMusicExpand()}>
                  <>
                  {musicExpand ? 'Collapse -' : 'Expand +'}
                  </>
                </button>
              </div>
            </div>
          
            <div className="wrapper">
              <div className={`columns is-tablet ${styles.articleBodyBottomRow}`}>
                <div className={`${styles.columnLeft} column is-5-tablet`}>
                  <h3 className={styles.h3}>
                  <span><b>C</b>arwash</span>
                  <span>R<b>e</b>cordin<b>g</b></span>
                  <span role="img" aria-label="Bubble Emoji">🫧</span>
                  </h3>
                  <AudioPlayer
                    className={styles.newPlayer}
                    src={carwash}
                    layout="horizontal-reverse"
                    controls
                    showFilledProgress={false}
                    defaultCurrentTime={'00:00'}
                    customProgressBarSection={[
                    RHAP_UI.CURRENT_TIME,
                    <p>&nbsp;/ 01:51</p>
                    ]}
                    showSkipControls={false}
                    showJumpControls={false}
                    customVolumeControls={[]}
                    customAdditionalControls={[]}
                    />
                </div>
                <div className={`${styles.columnRight} column is-7-tablet`}>
                  <div className={styles.authorDetails}>
                    <img src={sarah} alt="Sarah Youngquist" />
                    <div className={styles.name}>
                      <h4>Sarah Youngquist</h4>
                      <p className="a-h-5">Designer</p>
                    </div>
                  </div>
                  <p>“Getting a car wash has always been a healing, reset moment for me, and something I've found myself relying on even more since the pandemic began. When I'm having a particularly stressful week, I will make a point to drive through the car wash alone, just to have a moment. I'll close my eyes and let everything (quite literally) wash over me—the loud whoosh of the jets. The smell of the tri-color foam (!!!). The sprinkle of the spot-free rinse. And eventually, the whir of the dryers ushering me back into the world, feeling a little less stressed and a little more joyful.”</p>
                </div>
              </div>
            </div>

            <div className={styles.windowContainer}>
              <div className={styles.window} style={{backgroundImage: `url(${bkgblur})`}}>
                <p>Consider <span role="img" aria-label="Sparkle">✨</span></p>
                <h3 className={styles.h3}>
                <span>S<em>o</em>und</span>
                <img src={therapy} alt="Red Blob" />
                <span><b>T</b>he<b>r</b>apy</span>
                </h3>
                <p>Specific sounds are even powerful enough to influence our brain chemistry. <a href="https://www.psychiatria-danubina.com/UserDocsImages/pdf/dnb_vol30_noSuppl%203/dnb_vol30_noSuppl%203_141.pdf" target="_blank" rel="noreferrer">A new system for music therapy</a> suggests certain musical qualities might be used in tandem to stimulate specific neurotransmitters in a patient's brain depending on their needs. The release of Serotonin, Dopamine, and Oxytocin—the three main neurotransmitters correlated with happiness and wellbeing—is correlated with different experiences which may be triggered by sounds alone.</p>
                {therapyExpand &&
                  <>
                  <p>For example, our brains release dopamine in anticipation of rewards or when a reward is greater than expected. Exposure to music that purposefully builds compounding themes meant to increase the listener's anticipation could trigger the release of dopamine in a non-invasive way.</p>
                  </>
                }
                <button onClick={() => toggleTherapyExpand()}>
                  <>
                  {therapyExpand ? 'Collapse -' : 'Expand +'}
                  </>
                </button>
              </div>
            </div>

            <Contributors
              zeus={article.zeusContributors}
              artwork={article.otherContributorsArtwork}
            />
            <MoreArticles className={styles.moreFrom} id={article.id} issue={article.issue[0].id} />

          </div>
        </div>
      </article>
      <PreFooter style={{ marginTop: 0 }} />
    </Layout>
  )
}

export default Article

export const query = graphql`
  query($slug: String!) {
    craft {
      article: entry(slug: [$slug], section: "athenaArticles") {
        id
        title
        seomatic {
          metaTagContainer
          metaLinkContainer
          metaTitleContainer
          metaJsonLdContainer
        }
        ... on Craft_athenaArticles_athenaArticleCustom_Entry {
          articleSubTitle
          issue {
            id
            title
          }
          coverImages {
            ... on Craft_coverImages_BlockType {
              ratio_16x9 {
                id
                url
                width
                height
                ... on Craft_zjContent_Asset {
                  altText
                }
              }
              ratio_4x3 {
                id
                url
                width
                height
                ... on Craft_zjContent_Asset {
                  altText
                }
              }
            }
          }
          zeusContributors {
            id
            title
            uri
          }
          otherContributorsArtwork {
            ... on Craft_otherContributorsArtwork_BlockType {
              artistName
              artistWebsite
            }
          }
        }
      }
    }
  }
`
